import { Group, Layer } from '@pixi/layers';
import AudioApi from '@money.energy/audio-api';
import { ISongs, SlotId } from '../../../config';
import { fallBackReelPosition, getPlaceBetResult, getSlotOrderBySlotId } from '../../../gameUtils';
import { EventTypes, GameMode } from '../../../global.d';
import {
  setBetResult,
  setBetResultDuration,
  setCurrentIsTurboSpin,
  setCurrentReelSetId,
  setIsAutoSpins,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setLastSpinData,
  setSlotConfig,
} from '../../../gql/cache';
import { Logic } from '../../../logic';
import { States } from '../../../logic/config';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import type { BaseAnimation } from '../../animations/reel/baseAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import { BASE_REEL_ENDING_FORMULA, eventManager, REELS_AMOUNT, ReelState, SLOTS_PER_REEL_AMOUNT } from '../../config';
import type { Icon } from '../../d';
import { BaseReel } from '../reel/baseReel';
import type { Reel } from '../reel/reel';
import type Slot from '../slot';
import type { ReelsContainer } from './reelsContainer';

class BaseReelsContainer extends ViewContainer implements ReelsContainer {
  public reels: (Reel & ViewContainer)[] = [];

  public landedReels: number[] = [];

  public isSoundPlayed = false;

  public isForceStopped = false;

  public layer: Layer;

  public slotGroup: Group;

  private landingAnimationCount = 0;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.slotGroup = new Group(1, (slot) => {
      slot.zOrder = getSlotOrderBySlotId((slot as Slot).slotId);
    });
    this.layer = new Layer(this.slotGroup);
    this.initReels(reels, startPosition, this.slotGroup);
    Logic.the.currentSpinResult = this.getCurrentSpinResult();
    this.addChild(this.layer);
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.UPDATE_SCATTER_TEXTURE, this.setScatterTexture.bind(this));

    eventManager.addListener(EventTypes.CHANGE_REELS_DATA, this.changeReelsData.bind(this));

    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    eventManager.addListener(EventTypes.REEL_LANDED, this.checkLandedReels.bind(this));
    eventManager.addListener(EventTypes.REEL_LANDED_ANIMATION_PLAYED, this.checkLandedAnimations.bind(this));

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.landedReels = [];
      this.isForceStopped = false;
      this.getSpinAnimation().start();
    });
    this.sortableChildren = true;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.visible = true;

    if (settings.mode === GameMode.BASE_GAME && setLastSpinData().layout.length) {
      this.changeReelsData(setLastSpinData());
    }
  }

  public checkLandedReels(id: number): void {
    this.landedReels.push(id);
    if (this.landedReels.length === REELS_AMOUNT && this.landingAnimationCount === 0) {
      this.landedReels = [];
      eventManager.emit(EventTypes.REELS_STOPPED);
      const result = getPlaceBetResult(setBetResult());
      const scatterCount = result.bet.result.spinResult.filter((e) => e.id === SlotId.SC1).length;
      if (scatterCount >= 3) {
        AudioApi.play({
          type: ISongs.ScatterWin,
          stopPrev: true,
        });
        setTimeout(() => {
          AudioApi.play({
            type: ISongs.FeatureTrigger,
            stopPrev: true,
          });
        }, 2800);
      }
    }
  }

  public checkLandedAnimations(): void {
    if (this.landingAnimationCount > 0) {
      this.landingAnimationCount -= 1;
    }
    if (this.landedReels.length === REELS_AMOUNT && this.landingAnimationCount === 0) {
      this.landedReels = [];
      eventManager.emit(EventTypes.REELS_STOPPED);
      const result = getPlaceBetResult(setBetResult());
      const scatterCount = result.bet.result.spinResult.filter((e) => e.id === SlotId.SC1).length;
      if (scatterCount >= 3) {
        AudioApi.play({
          type: ISongs.ScatterWin,
          stopPrev: true,
        });
        setTimeout(() => {
          AudioApi.play({
            type: ISongs.FeatureTrigger,
            stopPrev: true,
          });
        }, 2800);
      }
    }
  }

  private throwTimeoutError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsTimeoutErrorMessage(true);
      fallBackReelPosition();
      eventManager.emit(EventTypes.THROW_ERROR);
    }
  }

  private removeErrorHandler(): void {
    this.reels[REELS_AMOUNT - 1]!.animation?.getWaiting().removeOnComplete(this.throwTimeoutError);
  }

  public getCurrentSpinResult(): Icon[] {
    const spinResult: Icon[] = [];
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        spinResult.push(
          setSlotConfig().icons.find((icon) => {
            const reel = this.reels[i as number] as BaseReel;
            return (
              icon.id ===
              reel.slots.find((slot) => slot.id === (reel.stopPosition + j + reel.size - 1) % reel.size)!.slotId
            );
          })!,
        );
      }
    }

    return spinResult;
  }

  public getSpinAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reels[i as number];
      const spinAnimation: ReelAnimation = reel!.createSpinAnimation();
      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getWaiting().addOnChange(() => {
          if (setBetResult() && !Logic.the.isReadyForStop) {
            Logic.the.isReadyForStop = true;
            this.removeErrorHandler();
            this.setupReelsTarget(setBetResultDuration());
          }
        });
        spinAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
      }
      this.reels[i as number]!.isPlaySoundOnStop = true;
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public rollbackReels(): void {
    if (Logic.the.state.name === States.IDLE) return;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i as number]!.animation?.getDisappearing().end();
      this.reels[i as number]!.animation?.getWaiting().end();
      this.reels[i as number]!.stopPosition = setLastSpinData()!.reelPositions[i as number] as number;
      this.reels[i as number]!.slots.forEach((slot, _id) => {
        slot.y = this.reels[i as number]!.getSlotY(slot);
        slot.toggleBlur(false);
      });
    }
    if (setIsAutoSpins()) setIsAutoSpins(false);
    Logic.the.changeState(States.IDLE);
  }

  public changeReelsData(input: { reelPositions: number[]; layout: SlotId[][] }): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i as number]!.changeReelData(
        input.layout[i as number]!,
        this.slotGroup,
        input.reelPositions[i as number]!,
      );
    }
  }

  public initReels(reels: SlotId[][], startPosition: number[], slotGroup: Group): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i as number] : 0;
      const reel = new BaseReel(i, reels[i as number]!, position!, slotGroup);
      this.reels[i as number] = reel;
      this.addChild(reel);
    }
  }

  public forceStopReels(): void {
    this.isForceStopped = true;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const animation = this.reels[i as number]!.animation! as BaseAnimation;
      animation.getDisappearing().duration = 0;
      animation.getWaiting().duration = 0;
      animation.getAppearing().duration = 0;
    }
  }

  public setupReelsTarget(responseDuration: number): void {
    const isStopped = Logic.the.isStoppedBeforeResult;
    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode === GameMode.BASE_GAME;
    const result = getPlaceBetResult(setBetResult());
    const speed = isTurboSpin ? 40 : 25;
    const { reelPositions } = result.bet.result;
    this.isSoundPlayed = false;
    for (let j = 0; j < REELS_AMOUNT; j++) {
      const waitingDuration = isTurboSpin ? responseDuration + 500 + j * 100 : responseDuration + 1250 + j * 300;
      const reel = this.reels[j as number] as BaseReel;
      if (setCurrentReelSetId() !== result.bet.reelSet.id) {
        reel.changeData(result.bet.reelSet.layout[j as number]!, this.slotGroup);
      }
      const waitingAnimation = reel.animation!.getWaiting() as Tween;
      const diff = (speed * waitingDuration) / 1000 - SLOTS_PER_REEL_AMOUNT;
      const beginValue = reelPositions[j as number]! + diff;
      const appearingBegin = beginValue - diff;

      const appearingAnimation = new Tween({
        object: this.reels[j as number]!,
        target: reelPositions[j as number]!,
        propertyBeginValue: appearingBegin + SLOTS_PER_REEL_AMOUNT,
        property: TweenProperties.STOP_POSITION,
        easing: BASE_REEL_ENDING_FORMULA,
        duration: isTurboSpin ? 0 : 250,
      });

      this.landingAnimationCount = SLOTS_PER_REEL_AMOUNT * REELS_AMOUNT;
      if (!isStopped) {
        waitingAnimation.propertyBeginValue = beginValue;
        waitingAnimation.target = reelPositions[j as number]! + SLOTS_PER_REEL_AMOUNT;
        waitingAnimation.duration = isStopped ? 0 : waitingDuration;
        appearingAnimation.propertyBeginValue = appearingBegin + 15;
        appearingAnimation.duration = isTurboSpin ? 0 : 250;
        appearingAnimation.addOnStart(() => {
          const delay = isStopped ? 0 : 100;
          const delayBeforeRunLanding = setTimeout(() => {
            this.handleLandingReel(j);
            clearTimeout(delayBeforeRunLanding);
          }, delay);

          this.reels[j as number]!.changeState(ReelState.APPEARING);
        });
        appearingAnimation.addOnChange(() => {
          this.reels[j as number]!.slots.forEach((slot, _i) => {
            slot.y = this.reels[j as number]!.getSlotY(slot);
          });
        });
        appearingAnimation.addOnComplete(() => {
          this.reels[j as number]!.changeState(ReelState.IDLE);
          eventManager.emit(EventTypes.REEL_LANDED, j);
        });
      } else {
        waitingAnimation.propertyBeginValue = beginValue;
        waitingAnimation.target = reelPositions[j as number]! + SLOTS_PER_REEL_AMOUNT;
        waitingAnimation.duration = isStopped ? 0 : waitingDuration;

        appearingAnimation.propertyBeginValue = appearingBegin + SLOTS_PER_REEL_AMOUNT;
        appearingAnimation.duration = isStopped ? 0 : 250;
        appearingAnimation.addOnStart(() => {
          this.reels[j as number]!.changeState(ReelState.APPEARING);
          const delay = isStopped ? 0 : 100;

          const delayBeforeRunLanding = setTimeout(() => {
            this.handleLandingReel(j);
            clearTimeout(delayBeforeRunLanding);
          }, delay);
        });
        appearingAnimation.addOnChange(() => {
          this.reels[j as number]!.slots.forEach((slot, _i) => {
            slot.y = this.reels[j as number]!.getSlotY(slot);
          });
        });
        appearingAnimation.addOnComplete(() => {
          this.reels[j as number]!.changeState(ReelState.IDLE);
          eventManager.emit(EventTypes.REEL_LANDED, j);
        });
      }
      // Edge case when you switch tabs after you click Spin and result is not here.
      // and you come back to tab after waiting animation duration is finished.
      if (waitingAnimation.ended) {
        waitingAnimation.duration = 1;
        waitingAnimation.target = 0;
        waitingAnimation.propertyBeginValue = 0;
        waitingAnimation.start();
      }
      reel.animation?.appendAnimation(appearingAnimation);
    }

    setCurrentReelSetId(result.bet.reelSet.id);
  }

  private handleLandingReel(reelId: number): void {
    const betResult = getPlaceBetResult(setBetResult());
    const slotId = betResult.bet.result.reelPositions[reelId as number];

    for (let i = -1; i < SLOTS_PER_REEL_AMOUNT - 1; i++) {
      const stoppedSlotId =
        (slotId! + this.reels[reelId as number]!.slots.length - i) % this.reels[reelId as number]!.slots.length;
      this.reels[reelId as number]!.slots[stoppedSlotId as number]!.onSlotStopped();
    }
  }

  public setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const reel = this.reels[x as number] as BaseReel;
      const slot = reel.slots.find((slot) => slot.id === (reel.stopPosition + y + reel.size - 1) % reel.size)!;
      if (slot && slot.slotId !== SlotId.SC1) slot.visible = visibility;
    });
  }

  public setScatterTexture(slots: number[], scatterId: SlotId): void {
    if (slots.length) {
      slots.forEach((slotId) => {
        const x = slotId % REELS_AMOUNT;
        const y = Math.floor(slotId / REELS_AMOUNT);
        const reel = this.reels[x as number] as BaseReel;
        const slot = reel.slots.find((slot) => slot.id === (reel.stopPosition + y + reel.size - 1) % reel.size)!;
        if (slot && slot.slotId === SlotId.SC1) slot.changeTexture(scatterId);
      });
    } else {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        const reelSlots = this.reels[i as number]!.slots;
        for (let j = 0; j < reelSlots.length; j++) {
          if (reelSlots[j].slotId === SlotId.SC1) reelSlots[j].changeTexture(scatterId);
        }
      }
    }
  }
}

export default BaseReelsContainer;
