import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import AudioApi from '@money.energy/audio-api';
import { cashierUrlAvailable, goToCashier } from '@money.energy/utils-fe';
import { ISongs } from '../../config/audio';
import { setGameError, setIsRevokeThrowingError } from '../../gql/cache';
import type { IGameError } from '../../gql/d';
import { gameErrorGql } from '../../gql/query';
import Button from '../UI/components/button';
import Popup from '../UI/components/popup';
import styles from './gameError.module.scss';

const defaultGameError: IGameError = {
  show: false,
  type: 'none',
  message: '',
};

const getMessageTypeError = (t: TFunction<'translation'>, message: string) => {
  if (message) {
    return message;
  }
  return t('errors.UNKNOWN.UNKNOWN');
};

const GameError: React.FC = () => {
  const { data } = useQuery<{ gameError: IGameError }>(gameErrorGql);
  const { t } = useTranslation();

  const btn = React.useMemo(() => {
    if (data?.gameError.type === 'balance') {
      return (
        <>
          {cashierUrlAvailable() && (
            <button className={`${styles['btn']} btn`} onClick={goToCashier}>
              {t('cashier')}
            </button>
          )}
          <Button
            className={styles.btn}
            intent="confirm"
            onClick={() => {
              setGameError(defaultGameError);
              setIsRevokeThrowingError(false);
            }}
            onMouseOver={handleHover}
          />
        </>
      );
    }

    return null;
  }, [data?.gameError, t]);

  if (!data) {
    return null;
  }

  const { message } = data.gameError;

  const handleHover = () => {
    if (isMobile || AudioApi.isPlaying(ISongs.UI_ButtonPress)) return;
    AudioApi.play({ type: ISongs.UI_ButtonHover });
  };

  return (
    <div className={data.gameError.show ? styles['backdrop']! : ''}>
      <Popup id="gameError" className="popup gameError" showNow={data.gameError.show} setShowNow={() => null}>
        <div className={styles['gameError']!}>
          <div className={styles['center']!}>
            <p className={styles['title']!}>{getMessageTypeError(t, message)}</p>
            <div className={styles['flex']!}>{btn}</div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default GameError;
