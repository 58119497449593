import _ from 'lodash';
import type { TAudioSprite } from '@money.energy/audio-api/dist/d';

// audiosprite -e 'mp4,ogg' -f 'howler' -c 2 Ambience.ogg AutoSpin.ogg BaseGame_Theme.ogg BetChange.ogg BigWin_Theme.ogg BigWin_ThemeEnd.ogg BigWin.ogg ButtonClose.ogg ButtonHover.ogg ButtonPress.ogg CountUpLoop.ogg CountUpStop.ogg FeatureTrigger.ogg HighWin.ogg MediumWin.ogg MegaWin.ogg MenuOpen.ogg ScatterAppear_1.ogg ScatterAppear_2.ogg ScatterAppear_3.ogg ScatterWin.ogg SmallWin.ogg SpinStart.ogg SpinStop.ogg SuperWin.ogg UltraWin.ogg -o ./sprite

export enum ISongs {
  Background = 'BaseGame_Theme',
  BigWin_Loop = 'BigWin_Theme',
  BigWin_End = 'BigWin_ThemeEnd',
  BigWin = 'BigWin',
  MegaWin = 'MegaWin',
  SmallWin = 'SmallWin',
  MediumWin = 'MediumWin',
  HighWin = 'HighWin',
  CountUp_Stop = 'CountUpStop',
  CountUp_Loop = 'CountUpLoop',
  UI_AutoSpin = 'AutoSpin',
  UI_BetChange = 'BetChange',
  UI_ButtonClose = 'ButtonClose',
  UI_ButtonPress = 'ButtonPress',
  UI_ButtonHover = 'ButtonHover',
  UI_MenuOpen = 'MenuOpen',
  UI_SpinStart = 'SpinStart',
  UI_SpinStop = 'SpinStop',
  CasinoAmbiance = 'Ambience',
  FeatureTrigger = 'FeatureTrigger',
  ScatterAppear_1 = 'ScatterAppear_1',
  ScatterAppear_2 = 'ScatterAppear_2',
  ScatterAppear_3 = 'ScatterAppear_3',
  ScatterWin = 'ScatterWin',
  UltraWin = 'UltraWin',
  SuperWin = 'SuperWin',
}

export const audioSprite: TAudioSprite = {
  [ISongs.CasinoAmbiance]: [0, 39000.02267573697, true],
  [ISongs.UI_AutoSpin]: [41000, 230.86167800453694],
  [ISongs.Background]: [43000, 86400, true],
  [ISongs.UI_BetChange]: [131000, 100.6122448979454],
  [ISongs.BigWin_Loop]: [133000, 32000, true],
  [ISongs.BigWin_End]: [166000, 14000.045351473915],
  [ISongs.BigWin]: [182000, 4200.02267573696],
  [ISongs.UI_ButtonClose]: [188000, 194.82993197277665],
  [ISongs.UI_ButtonHover]: [190000, 91.88208616780003],
  [ISongs.UI_ButtonPress]: [192000, 80.45351473921869],
  [ISongs.CountUp_Loop]: [194000, 450.68027210885475, true],
  [ISongs.CountUp_Stop]: [196000, 2622.902494331072],
  [ISongs.FeatureTrigger]: [200000, 8982.494331065765],
  [ISongs.HighWin]: [210000, 4800.022675736955],
  [ISongs.MediumWin]: [216000, 4800.022675736955],
  [ISongs.MegaWin]: [222000, 4800.022675736955],
  [ISongs.UI_MenuOpen]: [228000, 123.12925170067501],
  [ISongs.ScatterAppear_1]: [230000, 4650.022675736949],
  [ISongs.ScatterAppear_2]: [236000, 4500.022675736972],
  [ISongs.ScatterAppear_3]: [242000, 7200.02267573696],
  [ISongs.ScatterWin]: [251000, 15450.02267573699],
  [ISongs.SmallWin]: [268000, 4800.022675736955],
  [ISongs.UI_SpinStart]: [274000, 169.43310657597976],
  [ISongs.UI_SpinStop]: [276000, 73.35600907026674],
  [ISongs.SuperWin]: [278000, 4650.022675736977],
  [ISongs.UltraWin]: [284000, 5100.0226757369655],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Background]: 0.4,
  [ISongs.BigWin_Loop]: 0.5,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.BigWin]: 0.4,
  [ISongs.MegaWin]: 0.4,
  [ISongs.SmallWin]: 0.6,
  [ISongs.MediumWin]: 0.6,
  [ISongs.HighWin]: 0.6,
  [ISongs.CountUp_Stop]: 0.3,
  [ISongs.CountUp_Loop]: 0.6,
  [ISongs.UI_AutoSpin]: 0.4,
  [ISongs.UI_BetChange]: 0.4,
  [ISongs.UI_ButtonClose]: 0.4,
  [ISongs.UI_ButtonPress]: 0.4,
  [ISongs.UI_ButtonHover]: 0.3,
  [ISongs.UI_MenuOpen]: 0.4,
  [ISongs.UI_SpinStart]: 0.2,
  [ISongs.UI_SpinStop]: 0.2,
  [ISongs.CasinoAmbiance]: 0.4,
  [ISongs.FeatureTrigger]: 0.2,
  [ISongs.ScatterAppear_1]: 0.2,
  [ISongs.ScatterAppear_2]: 0.2,
  [ISongs.ScatterAppear_3]: 0.2,
  [ISongs.ScatterWin]: 0.2,
  [ISongs.UltraWin]: 0.2,
  [ISongs.SuperWin]: 0.2,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
